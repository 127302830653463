// @flow
import React, {Fragment, FunctionComponent} from 'react';
import {Grid2 as Grid, Typography} from "@mui/material";

interface TabularTextViewProps {
    displayPairs: Map<string, string>
}
const TabularTextView: FunctionComponent<TabularTextViewProps> = ({displayPairs}) => {
    return (
        <Grid container>
            {Array.from(displayPairs.entries()).map(([key, value]) => (
                <Fragment key={key}>
                    <Grid size={{xs: 6}}>
                        <Typography variant="subtitle1" color="text.secondary">
                            {key}
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 6}}>
                        <Typography variant="subtitle1" color="text.secondary">
                            {value}
                        </Typography>
                    </Grid>
                </Fragment>
            ))}
        </Grid>
    );
};

export default TabularTextView