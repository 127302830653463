import Typography from "@mui/material/Typography";
import FlexStack from "../Common/flex-stack";
import {Card, CardContent, Container, Grid2 as Grid, Stack} from "@mui/material";
import Divider from "@mui/material/Divider";
import React, {PropsWithChildren} from "react";
import AuthService from "../../services/auth-service";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import RouteService from "../../services/route-service";
import {AnimalRecordStatusValues} from "../../api/generated/herd-animal";
import {useHistory} from "react-router-dom";

const GridCard = ({children, href, image, alt, title, description}: PropsWithChildren<{
    href: string;
    image: string;
    alt: string;
    title: string;
    description: string;
}>) => {
    const history = useHistory();
    return (
        <Grid size={{xs: 12, sm: 6}}>
            <Card>
                <CardActionArea onClick={() => history.push(href)}>
                    <CardMedia
                        component="img"
                        height="240"
                        image={image}
                        alt={alt}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {description}
                        </Typography>
                        {children}
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
};

export const Dashboard = () => {
    return (
        <FlexStack>
            <Stack direction="column" alignItems="center" justifyContent="center">
                <Typography variant="h5" sx={{my: 4}}>Hallo {AuthService.getUsername()}!</Typography>
            </Stack>

            <Divider variant="middle" sx={{m: 2}}/>

            <Container>
                <Grid container spacing={3}>
                    <GridCard href={RouteService.expand(RouteService.HERD_GROUP, {
                        groupId: "all",
                        recordStatus: AnimalRecordStatusValues.HERD
                    })}
                              image="/herd.png"
                              alt="herd overview"
                              title="Herde verwalten"
                              description="Bekomme einen Überblick über deine Herde und erfasse neue Tiere."/>

                    <GridCard href={RouteService.HERD_PREGNANCIES_LIST}
                              image="/pregnancy.png"
                              alt="upcoming pregnancies"
                              title="Geburten planen"
                              description="Alle Trächtigkeiten und bevorstehenden Geburten deiner Herde auf einen Blick."/>

                    <GridCard href={RouteService.REGISTRY_ANIMAL_SEARCH}
                              image="/registry.png"
                              alt="registry search"
                              title="Register durchsuchen"
                              description="Durchsuche das zentrale Register nach Tieren anderer Vereinsmitglieder."/>

                    <GridCard href={RouteService.BREEDERS_LIST}
                              image="/breeders.png"
                              alt="breeder search"
                              title="Züchter finden"
                              description="Züchter und Betriebe suchen und finden."/>

                </Grid>
            </Container>
        </FlexStack>
    );
};